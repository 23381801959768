import React, { useState, useEffect, ReactElement } from 'react'
import { Button, Container, Row, Col, FormCheck } from 'react-bootstrap'
import { WhitespaceAPI } from '../../helpers/whitespace';
import { utils } from '../../FromElsewhere/utils'
import './CopyFacility.css'
import { Session } from '../../Session';
import { LoadSessionDetails } from '../../App';
import { sign } from 'crypto';
import { response } from 'express';
import { CombinedSets } from '../../helpers/CombinedSets';

interface SourceFacility {
	id : string;
	rev : string;
	umr : string;
	insuredName : string;
	updatedAt : string;
	placingBrokerChannel : string;
}

interface TargetTeam {
	name : string;
	channel : string;
	selected : boolean;
}

const MyTeams : TargetTeam[] = [];
const MyProgressMessages: string[] = [];
// let MyCombinedLines : CombinedSets.Root[] = [];
const RLPayloads: any[] = [];

export const CopyFacility = () => {
	const [sourceFacilities, setSourceFacilities] = useState<SourceFacility[]>( [] );
	const [sayuserMessage, sayuser] = useState("Choose a facility");
	const [selectedSF, setSelectedSF] = useState<SourceFacility | null>( null );
	const [userDetails, setUserDetails] = useState<any>( {} );
	const [teams, setTeams] = useState<TargetTeam[]>( [] );
	const [progressMessages, setProgressMessages] = useState<string[]>( [] );
	const [writtenSets,setWrittenSets] = useState<CombinedSets.Root[]>([]);
	const [signedSets,setSignedSets] = useState<CombinedSets.Root[]>([]);
	const [multiSectionStorer,setMultiSectionStorer] = useState<any>({ sections : [] });

	let targetTeams : TargetTeam[] = [];
	let targetIdx = -1;

	useEffect(() => {
		WhitespaceAPI.get('/api/user/myDetails').then( response => {
			setUserDetails( response.data );
			MyTeams.length = 0;
			response.data.teams.forEach( t => {
				const tt : TargetTeam = { name : t.name, channel : t.channel, selected : false };
				MyTeams.push( tt );
			});
			MyTeams.sort( ( a,b) => { return a.name.localeCompare( b.name ); } );

			const url = "/api/summary";
			const payload = { types : [ "Facilities" ] };
			WhitespaceAPI.post( url, payload ).then( response => {
				console.log( `${url} returned ${response.data.length} items`);
				const sfarray : SourceFacility[] = [];
				response.data.forEach( itm => { 
					const sf = mayMakeSourceFacility( itm );
					if( sf ) {
						sfarray.push( sf );
					}
					
				});
				console.log( `created ${sfarray.length} sfarray items`);
				setSourceFacilities( sfarray );
			});
		} )
	}, []);

	const mayMakeSourceFacility = ( itm : any ) : SourceFacility | null => {
		let result : SourceFacility | null = null;
		if( !itm.docs ) {
			return result;
		}
		itm.docs.forEach( doc => {
			if( doc.type == "RWPlacing" && doc.isFacility == true && doc.status == "Signed" && doc.id.endsWith( "::SIGNED") ) {
				result = { id : doc.id, rev: doc.rev, umr : doc.umr, insuredName : doc.insuredName, updatedAt : doc.updatedAt, placingBrokerChannel : doc.placingBrokerChannel };
				console.log( result );
			}
		});
		return result;
	}

	const pickSource = ( sf : SourceFacility ) => {
		MyProgressMessages.length = 0;
		setProgressMessages( MyProgressMessages.slice() );
		sayuser( `Selected ${sf.umr}`)
		setSelectedSF( sf );
		MyTeams.forEach( t => { t.selected = false; });
		setTeams( MyTeams.filter( t => { return t.channel != sf.placingBrokerChannel }) );
		const signedid = sf.id;
		const rootid = signedid.substring( 0, signedid.indexOf( "::SIGNED"));
		let url = `/api/lines/${rootid}/combinedSets`;
		console.log( `url is ${url}`);
		WhitespaceAPI.get( url ).then( response => {
			console.log( `done ${url}`);
			const writtenSets = response.data.filter( s => { return s.type == "RWWrittenLineSet"; });
			prepareRLPayloads( writtenSets );
			setWrittenSets( writtenSets );
			setSignedSets( response.data.filter( s => { return s.type == "RWSignedLineSet"; }) );

			url = `/api/documents/${rootid}::MS`;
			WhitespaceAPI.get( url ).then( response => {
				console.log( `done ${url}`);
				if( response.data && response.data._id && response.data._id.startsWith( rootid ) ) {
					setMultiSectionStorer( response.data );
				} else {
					setMultiSectionStorer( { sections : [] } );
				}
			});
		});
	}

	const prepareRLPayloads = ( writtenSets : CombinedSets.Root[] ) => {
		RLPayloads.length = 0;
		writtenSets.forEach( s => { 
			RLPayloads.push( prepareRLPayload( "", s ) );
		});
	}
	const prepareRLPayload = ( rev : string, writtenSet : CombinedSets.Root ) : any => {
		const payload : any = { _rev : rev, items: [] };
		writtenSet.contents.forEach( c => {
			const item : any = { uwrChannel : c.stamperChannel, saveStamps: true, lines: [] };
			c.impressions.forEach( imp => {
				const line : any = {};
				if( c.sectionIdentifiers.length ) {
					line.sectionIDs = [ c.sectionIdentifiers[0] ];
					line.sectionInfo = { index : 1, multiSectionId : c.sectionIdentifiers[0], multiSectionName : "TODO"};
				} else {
					line.sectionIDs = [];	// TODO - check in non-sectioned case
					// line.sectionInfo = {};
				}
				line.leadUnderwriter = ( c.role == "leader" );
				line.writtenDate = 1712066273;	// TODO
				line.facilityRole = c.role;
				line.conditions = { lineConditions: [], subjectivities: [] };
				
				const imp2 : any = {};
				imp2.references = imp.uwRefs.map( r => { return { reference : r, riskCodes : "" } } );
				imp2.writtenPercentage = imp.writtenLinePercentageString;
				imp2.stampID = imp.stamp.uniqueID;
				imp2.bureauMarket = imp.stamp.bureauMarket;
				imp2.businessUnit = imp.stamp.businessUnit;
				imp2.bureauMarketCode = imp.stamp.bureauMarketCode;
				imp2.bureauSubMarket = imp.stamp.bureauSubMarket;
				imp2.stampType = imp.stamp.stampType;
				imp2.shortName = imp.stamp.shortName || "";
				if( imp.brexitData ) {
					imp2.mirrorStampsData = [ makeMirrorStamp( imp.brexitData, "Applying to EEA risks" ) ];
				}
				if( imp.mirrorStamps && imp.mirrorStamps.length ) {
					imp2.mirrorStampsData = [];
					imp.mirrorStamps.forEach( ms => {
						imp2.mirrorStampsData.push( makeMirrorStamp( ms, "" ) );
					});
				}
				line.impressions = [ imp2 ];
				item.lines.push( line );
			});
			payload.items.push( item );
		});
		console.log( `Payload for ${writtenSet._id}`);
		console.log( JSON.stringify(payload, null, 4 ) );
		return payload;
	}

	const makeMirrorStamp = ( ms_or_bd : any, defaultcustomDescription : string ) => {
		const result : any = {};
		result.references = ms_or_bd.references.slice(); 
		result.businessUnit = ms_or_bd.stamp?.businessUnit || "";	// ": "809sadasdasd",
		result.bureauMarket = ms_or_bd.stamp?.bureauMarket || "";	// ": "24",
		result.bureauMarketCode = ms_or_bd.stamp?.bureauMarketCode || "";	// ": "4566",
		result.bureauSubMarket = ms_or_bd.stamp?.bureauSubMarket || "";	// ": "",
		result.stampID = ms_or_bd.stamp?.uniqueID || "";	// ": "STDF214948-4EAE-4F65-8CF0-9C8DA7D10E57",
		result.stampType = ms_or_bd.stamp?.stampType || "";	// ": "other",
		result.shortName = ms_or_bd.stamp?.shortName || "";	// ": "",
		result.mirrorStampCustomDescription = ms_or_bd.customDescription || defaultcustomDescription;
		return result;
	}

	const teamName = ( channel : string ) => {
		const t = userDetails.teams.find( t => { return t.channel == channel });
		return t ? t.name : channel;
	} 

	const ItemDisplay = ( { sf, idx } ) => {
		return (
			<Row onClick={ () => pickSource( sf ) } className='ClickableRow SmallText' key={idx} >
				<Col className='col-md-2'>{sf.umr}</Col>
				<Col className='col-md-2'>{teamName(sf.placingBrokerChannel)}</Col>
				<Col className='col-md-6'>{sf.insuredName}</Col>
				<Col className='col-md-2'>{utils.formatDateTime(sf.updatedAt,'d MMM YYYY')}</Col>
			</Row>
		)
	}

	const clickTeam = ( t1 ) => {
		const array : TargetTeam[] = [];
		MyTeams.forEach( t => { 
			if( t.channel != selectedSF?.placingBrokerChannel ) {
				if( t.channel == t1.channel ) {
					t.selected = !t.selected;
				}
				array.push( t ); 	
			}
		} );
		setTeams( array );
		saySelectedCount( array );
	}

	const saySelectedCount = ( array ) => {
		sayuser( `${array.filter( t => { return t.selected }).length} of ${array.length} teams selected`);
	}

	const setAll = ( selected : boolean ) => {
		const array : TargetTeam[] = [];
		MyTeams.forEach( t => { 
			if( t.channel != selectedSF?.placingBrokerChannel ) {
				t.selected = selected;
				array.push( t ); 
			}
		} );
		setTeams( array );
		saySelectedCount( array );
	}

	const proceed = () => {
		if( !selectedSF ) {
			return;
		}
		targetTeams = teams.filter( t => { return t.selected; });
		if( targetTeams.length == 0 ) {
			sayuser( "Select some teams first");
			return;
		}
		targetIdx = -1;
		MyProgressMessages.length = 0;

		copyToNextTeam();
		// targetTeams.forEach( t => {
		// 	copyToTeam( rootid, t );
		// });
	}

	const copyToNextTeam = () => {
		targetIdx += 1;
		if( targetIdx < 0 || targetIdx >= targetTeams.length ) {
			if( targetTeams.length > 1 ) {
				addProgress( `Completed processing on ${targetTeams.length} team(s)`);
			}
			return;
		}
		let signedid = selectedSF?.id || "";
		if( signedid.includes( "::SIGNED::") ) {
			signedid = signedid.substring( 0, signedid.indexOf( "::SIGNED::") + 8 );
		}
		const rootid = signedid.substring( 0, signedid.indexOf( "::SIGNED"));
		copyToTeam( rootid, targetTeams[ targetIdx ]);
	}
	

	const copyToTeam = ( rootid : string, t : TargetTeam ) => {
		addProgress( `Copying to ${t.name} = ${t.channel}`);
		const insuredName = `${selectedSF?.insuredName} (${t.name}) ${utils.formatDateTime( new Date(), "HH:mm")}`;
		const information = `Stub copy of ${selectedSF?.id || ""}`
		const payload = [
			{ "heading" : "UMR", "text" : ( selectedSF?.umr || "" ) },
			{ "heading" : "INSURED", "text" : insuredName },
			{ "heading" : "INFORMATION", "text" : information },
			{ "heading" : "WRITTEN LINES", "text" : "" }
		];

		const url = `/api/risks/newDraft?teamChannel=${t.channel}`;
		console.log( url );
		WhitespaceAPI.post( url, payload ).then( response => {
			if( !response.data || !response.data.id ) {
				addProgress( `ERROR calling ${url} for ${t.channel}`);
				return;
			}
			const newid = response.data.id;
			if( multiSectionStorer.sections.length > 0 ) {
				multiSectionSave( newid );
			} else {
				draftToFirmOrder( newid );
			}
		});
	}

	const multiSectionSave = ( newid : string ) => {
		const payload = multiSectionStorer.sections.map( s => { 
			return { index: s.index, multiSectionId : s.multiSectionId, multiSectionName: s.multiSectionName };
		});
		const url = `/api/sections/${newid}/save`;
		WhitespaceAPI.post( url, payload ).then( response => { 
			if( !response.data || !response.data.id ) {
				addProgress( `ERROR calling ${url}` ); 
			} else {
				addProgress( `${response.data.id} created by ${url}`);
				draftToFirmOrder( newid );
			}
		});
	}

	const draftToFirmOrder = ( newid : string ) => {
		let url = `/api/risks/${newid}/draftToFirmOrder`;
		WhitespaceAPI.post( url, {} ).then( response => {
			if( !response.data || !response.data.msg ) {
				addProgress( `ERROR calling ${url}` );
				return;
			}
			addProgress( `${response.data.msg} from ${url}` );
			url = `/api/risks/${newid}::FO`;
			WhitespaceAPI.get( url ).then( response => {
				if( !response.data || !response.data._id || !response.data._rev ) {
					addProgress( `ERROR calling ${url}` );
					return;
				}
				const id = response.data._id;
				const rev = response.data._rev;
				addProgress( `${id} revision ${rev}`);
				callRecordLine( id, rev, 0 );
			});
		});
	}

	const callRecordLine = ( id : string, rev: string, idx : number ) => {
		if( idx < 0 || idx >= writtenSets.length ) {
			const t = targetTeams[ targetIdx ];
			addProgress( `Completed recording lines for ${t.name}`);
			copyToNextTeam();
			return;
		}
		const wrls = writtenSets[ idx ];
		const description = describeWRLS( wrls );
		const payload = prepareRLPayload( rev, wrls );
		const url = `/api/risks/${id}/recordLine`;
		WhitespaceAPI.post( url, payload ).then( response => {
			if( !response.data || !response.data.msg ) {
				addProgress( `ERROR calling ${url} ${description}` );
				return;
			}	
			addProgress( `${url} returned ${response.data.msg} ${description}`);
			callRecordLine( id, rev, idx + 1 );
		});
	}

	const describeWRLS = ( wrls : CombinedSets.Root ) : string => {
		return `${wrls.contents[0].stamperChannel} ${wrls.contents[0].sectionIdentifiers.join(",")}`;
	}

	const addProgress = ( str : string ) => {
		MyProgressMessages.push( str );
		console.log( `${MyProgressMessages.length} messages after adding ${str}`);
		setProgressMessages( MyProgressMessages.slice() );
	}

	// UI //////////////////////////////////////////////////////////////////////////////////////////////////////////

	const TeamDisplay = ( { t } ) => {
		const className = `ClickableRow Above2 ${t.selected ? "TeamSelected" : ""}`;
		return (
			<div className={className} onClick={() => clickTeam( t )}>
				{t.name} {t.selected ? " - Selected" : ""}
			</div>
		)
	}

	const LineSetDisplay = ( { s } ) => {
		const mySet : CombinedSets.Root = s;
		return (
			<div>
				<div>{mySet._id}</div>
				{mySet.contents.map( ( c, idx ) => <SetContentDisplay c={c} key={idx} /> )}
			</div>
		)
	}
	const SetContentDisplay = ( { c } ) => {
		const x : CombinedSets.Content = c;
		let sectdesc = "No Sections";
		if( x.sectionIdentifiers.length == 1 ) { sectdesc = "Section " + x.sectionIdentifiers.join( ", ") }
		if( x.sectionIdentifiers.length > 1 ) { sectdesc = "Sections " + x.sectionIdentifiers.join( ", ") }
		return (
			<>
			<div>
				<div className='Indented1'>{x.role} : {x.businessUnit} <span className='LightGray'>{sectdesc}</span></div>
				{x.impressions.map( ( imp, idx ) => <ImpressionDisplay imp={imp} key={idx} /> )}
			</div>
			</>
		)
	}
	const ImpressionDisplay = ( { imp } ) => {
		const x : CombinedSets.Impression = imp;
		return (
			<>
			<div>
				<div className='Indented2 SmallText'>{x.writtenLinePercentageString}% {x.stamp.bureauMarket} {x.stamp.bureauMarketCode} {x.stamp.bureauSubMarket} {x.uwRefs.join( ", ") }</div>
			</div>
			</>
		)
	}

	const ProgressMessageDisplay = ( { msg } ) => {
		const cname = msg.startsWith( "ERROR" ) ? "WarningText" : "LightGray";
		return (
			<div className={cname}>{msg}</div>
		)
	}

	return (
		<>
		<div className='WarningBanner'>{sayuserMessage}&nbsp;</div>
		{!selectedSF && 
			<>
			<div>{sourceFacilities.length} facilities to choose from</div>
			{sourceFacilities.map( ( sf, idx ) => <ItemDisplay sf={sf} key={idx} idx={idx}/> ) }
			</>
		}
		{selectedSF && 
			<>
			<div>Selected {selectedSF.umr} / {selectedSF.insuredName} / {teamName(selectedSF.placingBrokerChannel)}</div>
			<div>
				<Button onClick={() => setSelectedSF(null)}>Back</Button>
				&nbsp;<Button onClick={() => setAll(true)}>Select All</Button>
				&nbsp;<Button onClick={() => setAll(false)}>Clear All</Button>
				&nbsp;<Button onClick={proceed} disabled={!teams.filter(t => { return t.selected}).length }>Proceed</Button>
			</div>
			<div className='Above12'>Click on the teams to copy to</div>
			<div>
			{teams.map( ( t, idx ) => <TeamDisplay t={t} key={idx}/> )}
			</div>
			<div className='Above12 SmallText'>
				{progressMessages.map( ( msg, idx ) => <ProgressMessageDisplay key={idx} msg={msg} /> )}
			</div>
			{writtenSets.length > 0 &&
				<div className='Above12'>
					<div>{writtenSets.length} lines to be copied</div>
					{writtenSets.map( ( s, idx ) => <LineSetDisplay s={s} key={idx} /> )}
				</div>
			}

			</>
		}
		</>
	)
 }